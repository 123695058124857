/*
People Page
-----------------------------------*/

.single-people{
	#subnav{
		display: none;
	}

	#content{
		width:100%;
		padding:0;
	}

} 

.people-header{
	padding:20px 0;
	display:flex;
	flex-direction:column;
	border-bottom:1px solid #000;
	margin-bottom:20px;

	@include media($medium-screen-up){
		flex-direction:row;
		justify-content:space-between;
	}

	.people-description{
		order:2;
		@include media($medium-screen-up){
			order:1;
		}
	}
	
	.people-image{
		margin-bottom: 20px;
		order:1;
		@include media($medium-screen-up){
			order:2;
		}
	}
}

 

.people-bio-content{
	display:flex;

	.left-sidebar{
		@include media($medium-screen-up){
			width:100px;
		}
	}
	.main-content{
		@include media($medium-screen-up){
			width:calc(100% - 100px);
		}
	}


	.social-share-links{
		padding:0;
		margin:0;
		display: flex;
		@include media($medium-screen-up){
			display: block;
		}
		
		li{
			&:before{display:none;}
		}
	}
}


/******************************************
Shortcode
******************************************/

.people-list{
	display:flex;
	flex-wrap:wrap;

	.people-card{
		width: 48%;
		margin-left:1%;
		margin-right: 1%;
		
		@include media($small-screen-up){
			width:24%;
			margin-left:0.5%;
			margin-right: 0.5%;
		}
	}
}

.people-card{
	max-width:230px;
	margin-bottom:20px;

	img{
		margin-bottom: 5px !important;
	}

	.people-name{
		display:block;
		line-height:1.2em;
	}
}

.people-bod{
	.people-name{
		
	}

	.title{
		display:block;
	}

	.organization{
		display: block;
	}
}



