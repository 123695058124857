/*
Programs Overview
-----------------------------------*/

.page-template-page-programs{
	#subnav{
		display: none;
	}

	#content{
		width:100%;
		padding:0;
	}

	.entry-content > .vc_row{
		@include media($large-screen-up){
			display: flex;
		}
	}

	.wpb_content_element{
		margin-bottom: 0;
	}

	.row{
		@include media($small-screen-up){
			justify-content: flex-start;
		}
		
	}

	/* list option*/
	.grid-item{
		width:24%;
	}
	


	.program-list-block h2{
		@include redTitle();
		margin-bottom: 5px;
		@include media($large-screen-up){
			text-align: left;
		}
	}

	.program-browse{
		max-width: 200px;
		width:49.5%;
		margin-right:1%;
		&:nth-child(2n){
			margin-right: 0;
		}
		margin-bottom: 20px !important;
		
		@include media($small-screen-up){
			max-width: none;
			width:24%;
			margin:0;
			margin-right:1.333%;
			&:nth-child(2n){
				margin-right: 1.333%;
			}
			&:nth-child(4n){
				margin-right: 0;
			} 
		}

		&.shortcode-half{
			@include media($small-screen-up){
				width:49.5%;
				margin-right:1%;
				&:nth-child(2n){
					margin-right: 0;
				} 
			}
		}

		img{
			display: block;
			margin-bottom:5px !important;
		}

		.program-title{
			font-family: $heading-font-family;
			font-size:18px;
			line-height: 1.1em;
		}
	}

/********************************
Subnav
*********************************/
	.programs-subnav{
		background: #f5f5f0;
		.vc_column-inner{
			padding-top:15px;
			padding-bottom: 15px;
		}

		h2{
			font-size:24px;
			color:$c-avacado;
			padding-bottom:5px;
			border-bottom:1px solid $c-avacado;
		}


		.menu{
			margin:0 0 15px 0;

			li{
				margin:0;
				padding:0;

				&:before{display:none;}

				a{
					display: block;
					font-family: $base-font-family;
					font-size:14px;
					line-height: 1.25em;
					padding:8px 12px;
					border-bottom:1px dotted #cacabb;
					color:#64645f;
				}
			}
		}
	}
}