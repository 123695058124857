/*
Banner
------------------------------------------------*/

#hero-banner{
	position: relative;
	overflow:hidden;
	@include media($small-screen-up){
		height:288px;
	}

	picture{
		@include media($small-screen-up){
			position: absolute;
			left:50%;
			margin-left:-960px;
			max-width: 1920px;
			width:1920px;
		}
		
	}
}

.page-intro{
	margin-top:-50px;
	

	.section-title{
		color:#fff;
		padding:15px 30px;
		background:$c-avacado;
		margin:0;
		font-size:28px;
		line-height: 1em;
		@include media($small-screen-up){
			font-size:48px;
		}
	}

	.page-intro-content{
		color:#fff;
		padding:20px 30px;
		background:$c-firefly;
		font-family: $heading-font-family;
		font-size:16px;
		@include media($small-screen-up){
			font-size:20px;
		}

		p{
			margin-bottom: 0;
		}

		a{
			color:#fff;
			text-decoration: underline;
		}
	}
}

.sticky-social{
	display:none;
	position: fixed;
	top:201px;
	top:25vh;
	right:0;
	z-index: 10;
	@include media($medium-screen-up){
		display: block;
	}

	ul{
		margin:0;
		padding:0; 


		li{
			margin-bottom: 5px;
			text-align: right;
			a{
				background:$c-avacado;
				color:#fff;
				display: inline-block;
				padding:0 20px;
				height:51px;
				line-height: 51px;
				text-align: center;
				font-size:21px;
				transition:all .25s;

				svg{
					display: inline-block;
					width:22px !important;
				}

				.text{
					@include elementInvisible;
				}

				&:focus,
				&:hover{
					padding-right:30px;
				}
			}

			button{
				cursor:pointer;
			}
		}
	}
}



