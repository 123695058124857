/*
Footer
------------------------------------------------*/

.footer-search-bar{
	box-shadow:inset 0px 2px 22px 0px rgba(98,57,38,0.5);
	background:#f5f5f0;
	padding:20px 0;
	@include media($medium-screen-up){
		padding:45px 0;
		box-shadow: inset 0px 5px 32px 0px rgba(98, 57, 38, 0.5); 
	}

	.section-inner{
		@include media($medium-screen-up){
				display: flex;
			justify-content: space-between;

			.search-col{
				width:48%;
			}
		}
	}

	label{
		font-family: $heading-font-family;
		font-size:15px;
		margin:0;
		color:$c-firefly;
		font-weight: 400;
		@include media($medium-screen-up){
			font-size:28px;
			margin-bottom: 10px;
		}
	}

	.form-row{
		position: relative;
	}

	input[type='text'],
	input[type='search']{
		height:30px;
		border:1px solid #b3b38c;
		color:#64645f;
		padding:0 10px;
		font-size:11px;
		@include media($medium-screen-up){
			height:50px;
			font-size:16px;
			padding:0 20px;
		}
	}



	.newsletter-signup{
		
		input[type='text']{
			padding-right:135px;
		}

		button{
			position:absolute;
			right:5px;
			top:5px;
			background:$c-firefly;
			color:#fff;
			text-transform: uppercase;
			font-size:11px;
			padding:5px 10px;
			border:1px solid transparent;
			@include media($medium-screen-up){
				top:10px;
				right:12px;
				font-size:15px;
				padding:8px 15px;
			}

			&:hover,
			&:focus{
				background:#fff;
				color:$c-firefly;
				border:1px solid $c-firefly;
			}
		}
	}


	.publication-search-form{
		input[type='search']{
			padding-right:50px;
		}

		button{
			position:absolute;
			right:5px;
			top:5px;
			background:$c-firefly;
			color:#fff;
			text-transform: uppercase;
			font-size:11px;
			padding:5px 10px;
			border:1px solid transparent;
			@include media($medium-screen-up){
				font-size:15px;
				padding:9px;
				top:9px;
				right:9px;
			}

			&:hover,
			&:focus{
				background:#fff;
				color:$c-firefly;
				border:1px solid $c-firefly;
			}
		}
	}

}




.f-w1{ 
	background:$c-firefly;
	color:#fff;
	padding:22px 0;
	text-align: center;
	@include media($medium-screen-up){
		padding:50px 0;
		text-align: left;
	}

	a:hover{
		text-decoration: none;
	}
}

.footer-row-1{
	@include media($medium-screen-up){
		display:flex;
		justify-content: space-between;
		align-items:flex-start;
	
		.col{
			max-width: 50%;
		}
	}
}
 
.footer-institutes ul{
	margin-bottom:20px;
	@include media($medium-screen-up){
		display:flex;
		justify-content:space-between;
		align-items:flex-start;
		flex-wrap: wrap;
		margin-bottom:55px;
	}


	li{
		max-width: 195px;
		margin:0 auto;
		@include media($medium-screen-up){
			width:48%;
			max-width: none;
			margin:0;
		}

		a{
			display: block;
			font-family: $heading-font-family;
			color:$c-whiterock;
			font-weight: 400;
			font-size:15px;
			line-height: 1.3em;
			@include media($medium-screen-up){
				font-size:20px;
				max-width: 255px;
			}

			&:after{
				content:'';
				background:#42718f;
				display: block;
				width:20px;
				height:1px;
				margin:8px auto;
				@include media($medium-screen-up){
					margin:20px 0;
				}
			}
			&:focus,
			&:hover{
				text-decoration: underline;
			}
		}

		&:nth-child(3),
		&:nth-child(4){
			@include media($medium-screen-up){
				a:after{display:none;}
			}
		}
	}
}

.footer-signup{
	display:flex;
	justify-content:space-between;
	@include media($medium-screen-up){
		//align-items:center;
	}

	.social-links-wrap{
		width:50%;

		li{
			margin-right:8px;
			display: none;
			@include media($medium-screen-up){
				margin:4px 15px 4px 0;
			} 

			&:first-child,
			&:nth-child(2),
			&:nth-child(3){
				display: inline-block;
			}

			a{
				display:block;
				border-radius: 50%;
				background:$c-whiterock;
				color:$c-firefly;
				font-size:20px;
				width:33px;
				height:33px;
				line-height: 33px;
				text-align: center;
				transition:all .25s;
				@include media($medium-screen-up){
					width:44px;
					height:44px;
					line-height: 44px;
					font-size:28px;
				}

				&:hover{
					transform:translateY(-3px);
				}
			}

			.text{
				@include elementInvisible;
			}
		}
	}

	.social-youtube{
		color:#fff;
		display: flex;
		font-family:$base-font-family;
		align-items:center;
		margin-top:24px;
		max-width: 185px;
		text-align: left;
		transition:all .25s;
		&:hover{
			transform:translateY(-3px);
		}

		span{
			line-height: 1.2em;
    		font-size: 14px;
    		padding-left:5px;
		}
		.fa-youtube{
			color:$c-whiterock;
			font-size:35px;
		}
	}

	.footer-btns{
		flex:1 0 auto;
		@include media($medium-screen-up){
			margin-left:35px;
		}
	}

	.btn{
		background:$c-whiterock;
		font-size:15px;
		display: block; 
		color:$c-firefly;
		margin-bottom: 6px;
		transition:all .25s;
		@include media($medium-screen-up){
			display: inline-block;
			margin-left:13px;
			margin-bottom: 0;
		}
		&:hover{
			background:#fff;
		}
	}
}


.footer-menu{
	margin:17px 0;
	font-family:$base-font-family;
	@include media($large-screen-up){
		position: absolute;
		right:75px;
		top:70px;
	}

	li{
		@include media($medium-screen-up){
			display: inline-block;
			margin-right: 10px;
		}
		@include media($large-screen-up){
			display: block;
			margin:0;
		}

		a{
			display: block;
			text-decoration: underline;
			color:#fff;
			font-size:13px;
			@include media($medium-screen-up){
				font-size:15px;
			}
		}
	}

}

.footer-copyright{
	font-family:$base-font-family;
	color:#fff;
	font-size:12px;
	@include media($medium-screen-up){
		font-size:15px;
		display: flex;
		align-items:center;
	}

	span{
		@include media($medium-screen-up){
			line-height: 1.2em;
			padding-right:7px;
			margin-right: 4px;
			border-right:1px solid #b3b38c;
		}
	}

}

.footer-util-menu{
	font-family:$base-font-family;
	margin-top:5px;
	@include media($medium-screen-up){
		margin:0;
	}

	li{
		display: inline-block;
		vertical-align: center;
		
		margin-left:-4px;
		
		a{
			display: block;
			text-decoration: underline;
			color:#fff;
			padding:0 8px;
			line-height: 1.2em;
			border-right:1px solid #b3b38c;
		}

		&:last-child a{
			border-right:none;
		}
	}
}


#credit{
	font-family:$base-font-family;
	a{
		color:#42718f;
		text-decoration: underline;
		@include media($medium-screen-up){
			font-size:15px;
		}
	}
}
