/*
Layout
------------------------------------------------*/
#main { 
	position: relative; 
}
   
.m-w1{
	& > .section-inner{
		@include media($medium-screen-up) {
			display:flex;
			align-items:flex-start;
			padding-bottom:40px;
		}
	}
}

.breadcrumbs{
	height:52px;
}

#content {
	background:#fff;
	padding-bottom:40px;
	@include media($medium-screen-up) {
		width:74%;
		order:1;
		padding-left:35px;
	}
}

.sidebar{
	@include fillScreen;
	background:#f5f5f0;
	padding:20px;

	@include media($medium-screen-up) {
		margin:0;
		width:26%;
		order:0;
		padding:20px 15px;
	}
}



a.subnav-title{
	display: block;
	font-size:24px;
	color:$c-avacado;
	font-family: $heading-font-family;
	padding-bottom: 5px;
	border-bottom:1px solid $c-firefly;
	color:inherit;
	
}

.subnav-links{
	font-family:$base-font-family;
	li{
		a{
			display: block;
			position: relative;
			padding:6px 12px;
			font-size:14px; 
			color:#64645f;
			border-bottom:1px dotted #cacabb;
		}

		&.current_page_item{
			a{
				color:$c-firefly;
				&:before{
					content:'';
					display: block;
					position: absolute;
					left:1px;
					top:18px;
					width:5px;
					height:5px;
					border-radius:50%;
					background:$c-maroon;
				}
			}
		}
	}
}



