// Neat Overrides
@import "lib/bourbon/app/assets/stylesheets/bourbon";
@import "../lib/neat/app/assets/stylesheets/neat-helpers"; // or "../neat/neat-helpers" when not in Rails

$column: 4.375em;/*70px/16*/
$gutter: 1.875em; /*30px/16*/

$max-width: 1170px;
//$visual-grid: true !global; /* comment this out to hide grid */
$global-edge-padding:15px;

// Neat Breakpoints
$small-screen: 640px;
$small-screen-down: 639px; 

$medium-screen: 768px; 
$medium-screen-down: 767px; 

$large-screen: 960px;
$ex-large-screen:1200px;

$small-screen-up: new-breakpoint(min-width $small-screen 12);
$medium-screen-up: new-breakpoint(min-width $medium-screen 12);
$large-screen-up: new-breakpoint(min-width $large-screen 12);
$ex-large-screen-up: new-breakpoint(min-width $ex-large-screen 12);

$small-screen-down: new-breakpoint(max-width $small-screen-down 12);
$medium-screen-down: new-breakpoint(max-width $medium-screen-down 12);
$large-screen-down: new-breakpoint(max-width $large-screen 12);
$ex-large-screen-down: new-breakpoint(max-width $ex-large-screen 12);

//This breakpoint goes last to allow the visual editor to work.
$mobile: new-breakpoint(max-width $medium-screen 4);

@import "lib/neat/app/assets/stylesheets/neat"; 

//set visual grid so takes into account side padding
@include media($ex-large-screen-down) {
	body:before{
		left:$global-edge-padding !important;
		width:calc(100% - 30px) !important;
		margin: 0 !important;
	}
}
