/*
Publications Overview
-----------------------------------*/

.page-template-page-publication-overview {

	#subnav{
		display: none;
	}

	#content{
		width:100%;
		padding:0;
	}


	/************************
	Publication Search
	**************************/
	.featured-books{
		border-bottom:1px solid #000;
		margin-bottom: 50px;

		h2{
			@include redTitle;
		}

		.row{
		@supports(display:grid){
				display: grid;
				grid-template-columns: 48% 48%;
				grid-column-gap: 1%;
	  			grid-row-gap: 20px;
	  			@include media($medium-screen-up){
	  				grid-template-columns: 24% 24% 24% 24%;
	  			}
			}
			
	  		
		}

		.grid-item{
			max-width: 250px;
		    width: 48%;
		    text-align: center;
		    margin-bottom: 20px;
		    @supports(display:grid){
		    	width:auto;
		    }
		}
	}

	.grid-item{
		width:100%;
		@include media($small-screen-up){
			width:48%;
		}	
	}

	.publication-browse{
		width:auto;
		padding:12px 0;
		background:transparent;
		margin-bottom:40px !important;

		.primary-topic{
			// padding:0;
			// background:transparent;
			// border-bottom:1px solid $c-maroon;
			// margin-bottom: 5px;

			// span{
			// 	padding:3px 8px;
			// 	display: block;
			// 	background:$c-avacado;
			// 	margin-bottom: 2px !important;
			// }
		}

		.publication-title{
			font-size:26px;

			span{
				display: block;
				font-size:20px;
			}
		}

		.authors{
			font-family: $base-font-family;
			color:shade($c-avacado, 25%);
			font-style:italic;
			font-size:14px;
			line-height: 1.2em;
		}
 
		.bottom-meta{
			padding:0;
			border:none;
			color:$c-maroon;

			&>span{
				text-transform: none;
				display: inline-block;
				font-size:14px;
			}

			.publication-type{
				color:$c-maroon;
				font-style: normal;
				padding-right:5px;
			}

			.date{
				padding-left:5px;
			}

			.publication-type + .date{
				border-left:1px solid $c-maroon;
			}
		}	
	}
}

.load-more{
	text-align:center;
}

.btn-load-more{
	border:none;
	font-family:$base-font-family;
	color:$c-maroon;

	&:hover{
		background:transparent;
		color:$c-maroon;
	}

	a{
		color:inherit;
	}
}

.publication-filter{
	padding:15px;
	background:#f5f5f0;

	h2{
		font-size:24px;
		color:$c-firefly;
	}

	.search-bar{
		position: relative;

		input[type='text']{
			height:48px;
			font-size:14px;
		}

		button{
			position: absolute;
			right:10px;
			top:9px;
			background:$c-firefly;
			color:#fff;
			padding: 8px;
    		font-size: 13px;
		}
	} 

	.accordion{
		margin:0;
	}

	.card{
		background:#fff;
		border:1px solid $c-avacado;
		margin-bottom: 10px;
	}

	.card-header{
		font-size:14px;
		position: relative;
		padding:15px 10px;
		margin-bottom: 0;
		text-transform: none;
		color:#64645f;
		font-weight: normal;
		cursor: pointer;

		&:after{display:none;}

		.fa-angle-up{
			position: absolute;
		    right: 10px;
		    top: 6px;
		
			display: inline-block;
			padding:5px 5px;
			width:35px;
			height:35px;
			border-radius:5px;
			background:$c-firefly;
		    text-align: center;
		    font-size: 20px;
		    color:#fff;
		    font-weight: normal;
			transition:all .25s;
			border:none;
			font-weight: 900 !important;
		}

		&.open{
			.fa-angle-up{
				transform: rotate(180deg);
			}
		}
	}

	.form-check-label{
		font-size:14px;
		font-weight:normal;
		position: relative;

		span{
			
		}

		.icon{
			position: absolute;
			top:6px;
			left:0;
			width:15px;
			height:15px;
			line-height: 15px;
			background:#fff;
			border:1px solid #ccc;
			border-radius:50%;
			color:$c-maroon;
			font-size:10px;
			text-align: center;
			font-size:0;
			display: none;
		}

		input{
			//visibility: hidden;
			&:checked + .icon{
				content:'\f00c';
				font-size:12px;
			}
		}
	}

	.card-block{
		padding:0 10px;
		overflow: hidden;
		max-height: 0;
		transition:all .25s;

		&.open{
			padding:10px;
			max-height: 500px;
		}
	}
}

