/*
Search
------------------------------------------------*/


.page-template-page-publication-search{

	#subnav{
		display: none;
	}

	#content{
		width:100%;
		padding:0;
	}

	.wpb_column.vc_col-sm-7{
		margin-top:50px;
	}
}