/*
Publication Detail
-----------------------------------*/

.single-publication {
	#subnav{
		display: none;
	}

	#content{
		width:100%;
		padding:0;
	}

	.grid-item{
		width:24%;
	}
}

.publication-header{
	padding:44px 0 25px 0;
	@include media($medium-screen-up){
		display:flex;
	}


	.publication-banner{
		flex:1 0 auto;
		margin-bottom: 20px;
		margin-right: 55px;
		@include media($medium-screen-up){
			margin-bottom: 0;
		}
	}

	h1{
		font-size:34px;
	}

	.authors{
		color:$c-avacado;
		font-style:italic;
	}

	.publication-meta{
		color:$c-maroon;
		font-family: $base-font-family;
		margin-bottom: 25px;

		span{
			display: inline-block;
			border-right:1px solid $c-maroon;
			padding:0 10px;
			font-size:16px;
			line-height: 1em;

			&:first-child{
				padding-left:0;
			}

			&:last-child{
				border-right:0;
			}
		}
	}

	.short-description{
		font-size:20px;
		line-height: 1.5em;
	}
}


.publication-button{
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-wrap:wrap;
	padding:0px 20px;
	border-top:1px solid $c-avacado;
	border-bottom:1px solid $c-avacado;
	@include media($medium-screen-up){
		padding:0px 45px;
	}

	.btn{
		margin:12px 5px;
		flex: 1 1 100%;
		@include media($small-screen-up){
			flex: 1 1 auto;
		}
	}
}

/*******************************
Layout 
********************************/

.publication-content{
	padding:30px 0;
	@include media($medium-screen-up){
		display: flex;
	}

	.content {
		background:#fff;
		@include media($medium-screen-up) {
			width:74%;
			order:1;
			padding-left:35px;
		}
	}

	.sidebar{
		@include fillScreen;
		background:#f5f5f0;
		padding:20px;

		@include media($medium-screen-up) {
			margin:0;
			width:26%;
			order:0;
			padding:20px 15px;
		}	
	}
}

/************************************
Sidebar
************************************/

.sidebar-box{
	margin-bottom:24px;
	padding-left:30px;
	h2{
		@include topicTitle();
		margin-left:-30px;
	}

	.publication-browse{
		width:100%;
		padding:0;
	}
}

.sidebar-related{
	ul{
		margin: 0;
		li{
			list-style: none;
			margin:0;
			padding:0;

			&:before{display:none;}

			a{
				display: block;
				font-size:20px;
				line-height: 30px;
			}
		}
	}
}

/************************************
Content
************************************/

.publication-description{
	h2{
		font-size:20px;
		line-height: 1.2em;
		text-transform: uppercase;
		color:$c-avacado;	
		padding-bottom:5px;
		border-bottom:1px solid $c-firefly;
		margin-bottom:25px;
	}
}

/************************************
Related
************************************/

.single-publication  .related-topic{
	h2{
		@include redTitle(); 
		text-align: center;
	}

	.row{
		@supports(display:grid){
			display: grid;
			grid-template-columns: 48% 48%;
			grid-column-gap: 1%;
  			grid-row-gap: 20px;
  			@include media($medium-screen-up){
  				grid-template-columns: 24% 24% 24% 24%;
  			}
		}
		
  		
	}

	.grid-item{
		max-width: 250px;
	    width: 48%;
	    text-align: center;
	    margin-bottom: 20px;
	    @supports(display:grid){
	    	width:auto;
	    }
	}
}



