/*
Home
------------------------------------------------*/

.home{

	.breadcrumbs{display:none;}
	
	#content{
		width:100%;
		padding:0;
	}
	
	.sidebar{
		display: none;
	}

	.section-header{
		position: relative;

		.btn{
			// margin-bottom: 20px !important;
			// margin-top:-20px;

			//@include media($medium-screen-up){
				position: absolute;
			    right: 15px;
			   	bottom:12px;
			    margin:0 !important;
			//}
		}
	}

	.c-w1 h2:not(.publication-title):not(.topic-title){
		@include redTitle;
	}


}



/*doc
---
title: Testimonials
name: testimonials
category: content_classes
---

<div class="testimonial-wrap">
<div class="testimonal">
In addition to the uncertainty and unpredictability of the Trump administration’s policy...
<div class="author">Nong Hong</div>
</div>
<div class="testimonal">
At the root of recent tensions between Singapore and China lie fundamental differences.
<div class="author">Ja Ian Chong</div>
</div>

</div>
*/
.testimonial-wrap{
	display: flex;
	background:url(../img/quote-top.png), url(../img/quote-bottom.png);
	background-position:0 0, right bottom;
	background-size:35px auto;
	background-repeat:no-repeat;
	padding:40px;
	@include media($medium-screen-up){
		padding:55px 50px;
		background-size:50px auto;
	}

	.testimonal{ 
		flex: 1 1 50%;
		padding:0;
		font-size:15px;
		@include media($medium-screen-up){
			padding:0 20px;
			font-size:18px;
			line-height: 1.44em;
		}
			
		&:last-child{
			@include media($medium-screen-down){
				display:none;
			}
		}

		p{
			margin-bottom: 0;
		}

		.author{
			font-family: $base-font-family;
			color:$c-avacado;
			font-style: italic;
			font-size: 11px;
			margin-top:5px;
			@include media($medium-screen-up){
				font-size: 14px;
			}
		}
	}
}


/*************************
Current Issues
*************************/

#content .current-issue{
	border:1px solid $c-firefly;
	padding:10px;
	clear:both;
	margin: 0 0 10px 0;
	@include media($large-screen-up){
		margin-bottom: 60px;
	}
	.wpb_column{
		background:#f3f3f0;
		
	}

	.vc_column-inner {
		padding:22px;
	}

	.in-this-issue .wpb_wrapper{
		@include media($large-screen-up){
			display: flex;
			width:100%;
		}
	}

	h2{
		font-family: $heading-font-family;
		font-size:25px;
		color:$c-avacado;
		text-transform: uppercase;
		border:none;
		text-align: left;
		font-weight: 400;
		@include media($large-screen-up){
			font-size:34px;
		}
	}

	.btn{
		top:0;
		@include media($medium-screen-down){
			position: static;
			margin-top:-20px !important;
			margin-bottom: 20px !important;
		}
	}

	.issue-img{
		display: block;
		margin-right: 35px;
		flex:1 0 232px;
		margin-bottom: 30px;
		@include media($large-screen-up){
			margin-bottom: 0;
		}

		img{
			box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.6); 
		}
	}

	.issue-content{
		flex:1 1 auto;
	}

	.content{
		//@include media($medium-screen-up){
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		//}

		.publication-short{
			@include media($medium-screen-up){
				width:46%;
			}
		}
	}
}


/************************************
Featured Initiatives
**************************************/

.row-initiatives{
	background:#f3f3f0;
	padding:20px 0;
	margin-bottom:20px;
	@include media($medium-screen-up){
		padding:50px 0;
		margin-bottom: 35px;
	}

	.wpb_wrapper{
		height:100%;
	}
}

.featured-initiatives{
	margin-bottom:0 !important;
	height:100%;


	&>.wpb_wrapper{
		display:flex;
		align-items:stretch;
		height:100%;
	}

	.map-left{
		width:40%;
		background: #031e2f;
		padding:15px;
		color:#fff;
		@include media($medium-screen-up){
			padding:40px 20px;
		}

		h3{
			font-family: $heading-font-family;
			color:#fff;
			font-size:20px;
			@include media($medium-screen-up){
				font-size:35px;
			}
		}

		p{
			font-family: $base-font-family;
			font-size:11px;
			line-height: 1.2em;
			@include media($medium-screen-up){
				line-height: 1.46em;
				font-size:15px;
			}
		}
	}

	.map-right{
		background:url(../img/ocean.jpg) 0 0 no-repeat;
		background-size:cover;
		width:60%;
		padding:20px;
		@include media($medium-screen-up){
			padding:40px 20px;
		}

		&>h3{
			font-family: $heading-font-family;
			color:#fff;
			font-size:13px;
			text-transform: uppercase;
			@include media($medium-screen-up){
				font-size:35px;
				margin-bottom: 20px;
			}
		}

		.publication-short{
			margin-bottom: 10px;
			@include media($medium-screen-up){
				margin-bottom: 20px;
			}

			.publication-title{
				color:#e6e6c8;
				font-size:15px;
				@include media($medium-screen-up){
					font-size:24px;
				}
			}
			.authors{
				color:#fff;
			}
		}
	}
}


.row-initiatives .center-for-innovation{
	@include media($medium-screen-down){
		margin:15px;
	}

	.vc_column-inner {
		background:#49483d;
		color:#fff;
		padding:25px 15px;
		font-size:18px;
		line-height:23px;
		@include media($medium-screen-up){
			padding:30px;
		}
	}

	h3{
		color:#fff;
		font-size:20px;
		@include media($medium-screen-up){
			// font-size:34px;
			font-size: 25px;
    		margin-bottom: 15px;
		}
	}
}



/********************************
Expert Spotlight
********************************/

#content .expert-spotlight{
	background:url(../img/bg-expert-spotlight.png) center top no-repeat;
	background-size:cover;
	padding:20px 0;
	margin-bottom:15px;
	color:#fff;

	@include media($medium-screen-up){
		padding:40px 0;
	}

	h2{
		color:$c-whiterock;
		border-color:$c-whiterock;
		text-align: center;

	}

	.expert-block{
		text-align: center;
		@include media($medium-screen-up){
			display: flex;
			justify-content: center;
			align-items:center;
			text-align: left;
		}
	}

	img{
		margin:0 auto;
		@include media($medium-screen-up){
			margin-right: 42px;
		}
	}

	.content{
		flex:0 1 478px;
	}

	h3:not(.publication-title){
		color:#fff;
		font-size:20px;
		margin-bottom: 20px;
		@include media($medium-screen-up){
			font-size:34px;
		}
	}

	p{
		margin-bottom: 20px;
	}

	.publication-short{
		max-width: 300px;
		text-align: left;
		margin:0 auto;
		@include media($medium-screen-up){
			margin:0;
		}
	}

	.publication-title{
		color:$c-whiterock;
	}

	.authors,
	.date{
		color:#fff;
	}
}

/******************************************
Featured Books
*******************************************/
.block-homepage-5{
	.shortcode-book{
		width:48%;
		@include media($medium-screen-up){
			width:23%;
			
		}
	}
}









 
