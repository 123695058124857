/*
Global Short Code Styles
---------------------------------------------*/


.row{ 
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items:flex-start;
}

.primary-topic{
	background:$c-maroon;
	position:absolute;
	left:0;
	bottom:calc(100% + 1px);
	padding:0 8px;
	width:100%;
	//width:calc(100% - 20px);
	font-family: $base-font-family;
	font-weight: 600;
	color:#fff;
	text-transform:uppercase;
	font-size:12px;
	@include media($large-screen-up){
		font-size:15px;
		max-width: 290px;
		padding:5px 8px;
		line-height: 1.2em;
	}
}

.publication-type{
	font-family: $base-font-family;
	font-style:italic;
	color:#fff;
	font-size:10px;
	line-height:1.2em;
	display:block;
	@include media($large-screen-up){
		font-size:14px;
	}
}

.short-description{
	font-family: $heading-font-family;
	margin-bottom:10px;
	line-height:1.5em;
	font-size:11px;
	@include media($large-screen-up){
		line-height:1.44em;
		font-size:18px;
	}
}




/**************************
Home Banner
***************************/

.home-banner-section{
	margin-bottom:44px;

	.banner-text{
		min-height: 280px;
		margin-bottom:0;
		@include media($medium-screen-up){
			height:595px;
			position: relative;
		}
	}

	.section-inner{
		position: relative;
	}

	.banner{
		min-width: 100%;
		@include media($medium-screen-up){
			max-width: none;
			width:1920px;
			position: absolute;
			left:50%;
			margin-left:-960px;
			top:0;
		}
	}

	.publication-info-wrap{
		position:absolute;
		left:0;
		bottom:0;
		width:100%;

	}

	.publication-info{
		position: relative;
		background:rgba(0,0,0,.7);
		color:#fff;
		padding:15px 20px;
		max-width: 468px;
		bottom:25px;
		@include media($medium-screen-up){
			padding:30px;
			position: absolute;
			right:0;
			bottom:90px;
		}
	}

	.primary-topic{
		font-size:10px;
		width:100%;
		@include media($medium-screen-up){
			font-size:13px;
			max-width: 100%;
		}
	}
	

	.publication-title{
		display: block;
		@include media($medium-screen-up){
			margin-bottom: 8px;
		}


		a{
			font-size:20px;
			color:$c-whiterock;
			line-height: 1.2em;
			display: block;
			@include media($large-screen-up){
				font-size:30px;
			}
		} 
	}

	.short-description{
		font-size:15px;
		line-height: 1.4em;
		@include media($medium-screen-up){
			font-size:18px;
		} 
	}

	.authors{ 
		font-family: $base-font-family;
		font-size:12px;
		font-style:italic;
		color:$c-whiterock;
		@include media($medium-screen-up){
			font-size:14px;
		}
	}

	.photo-credit{
		display: none;
		@include media($medium-screen-up){
			display: block;
			position: absolute;
			bottom:0;
			left:0;
			background:#000;
			color:#fff;
			padding:0 22px;
			font-family: $base-font-family;
			font-size:13px;
			font-style:italic;
			color:#909090;
		}
	}
}


/**************************
Publication Card
***************************/

.publication-card{
	margin:25px 0 50px 0 !important;
	width:100%;

	&.shortcode-half{
		@include media($small-screen-up){
			width:48%;
		}
	}


	.card{
		position: relative;
	}

	img{
		width:100%;
	}
	
	.publication-details{
		position: absolute;
		right:0;
		bottom:0;
		width:100%;
		background:rgba(0,0,0,.7);
		color:#fff;
		padding:16px 12px;
		max-height: 100%;
		@include media($medium-screen-up){
			padding:16px 35px;
			min-height: 132px;
		}
	}

	.publication-title{
		font-size:20px;
		@include media($medium-screen-up){
			font-size:26px;
		}

		a{
			color:$c-whiterock;

			span{
				display: block;
				font-size:15px;
				@include media($medium-screen-up){
					font-size:22px;
				}
			}
		} 
	}

	&.shortcode-wide{
		.publication-details{
			
			@include media($large-screen-up){
				display: flex;
			    flex-direction: column;
			    justify-content: center;
				width:75%;
				height:100%;
			}

			@include media($ex-large-screen-up){
				width:50%;
			}
		}
	}

	.photo-credit{
		font-family: $base-font-family;
		font-size:13px;
		color:#a1a1a1;
		font-style: italic;
	}

}


/**************************
Publication Topic
***************************/

.publication-topic{
	margin-bottom:35px;

	.topic-title{
		@include topicTitle();
	}

	.publication{
		max-width: 240px;
		margin:0 auto 20px auto;
		@include media($medium-screen-up){
			max-width: 100%;
		}
	}

	.publication-thumbnail{
		display: block;
		margin-bottom: 10px;
	}

	.publication-title{
		font-family: $heading-font-family;
		font-size:15px;
		color:$c-firefly;
		margin-bottom: 0;
		@include media($medium-screen-up){
			font-size:20px;
		}

		a{
			color:inherit;
		}
	}

	.authors{
		color:$c-avacado;
		font-family: $base-font-family;
		font-style: italic;
		font-size:11px;
		line-height: 1.5em;
		@include media($medium-screen-up){
			font-size:14px;
		}
	}
}


/***********************************
Event Browse
***********************************/

.event-browse{
	position: relative;
	background:#f0f0ec;
	max-width: 240px;
	margin:45px auto 20px auto;
	padding:12px 12px 0 12px;
	
	@include media($medium-screen-up){
		max-width: none;
		width:30%;
		padding:30px 25px 0 25px;
	}


	.event-title{
		font-family: $heading-font-family;
		font-size:15px;
		color:$c-firefly;
		margin-bottom: 15px;
		@include media($medium-screen-up){
			font-size:20px;
		}

		a{
			color:inherit;
		}
	}

	.categories{
		font-family: $base-font-family;
		color:$c-avacado;
		line-height: 1.25em;
		font-size:10px;
		margin-bottom: 10px;
		@include media($medium-screen-up){
			font-size: 15px;
		}
	}

	.type{
		text-align: right;
		line-height: 1.25em;

		.type-label{
			display: inline-block;
			font-family: $base-font-family;
			color:$c-maroon;
			font-size:12px;
			text-transform: uppercase;

			svg{
				font-size:18px;
			}
		}
	}


	.bottom-meta{
		
		border-top:1px solid $c-maroon;
		padding:7px 0;
		font-family: $base-font-family;
		color:$c-firefly;
		font-size:11px;
		text-transform: uppercase;
		@include media($medium-screen-up){
			padding:14px 0;
			font-size:13px;
		}

		&>span{
			display: inline-block;
			line-height: 1em;
			text-transform: uppercase;
		}
	}
}


/***********************************
Publication Browse
***********************************/
.publication-browse{
	position: relative;
	background:#f0f0ec;
	max-width: 240px;
	margin:45px auto 20px auto;
	padding:12px 12px 0 12px;
	
	@include media($medium-screen-up){
		max-width: none;
		width:30%;
		padding:30px 25px 0 25px;
	}


	.publication-title{
		font-family: $heading-font-family;
		font-size:15px;
		color:$c-firefly;
		margin-bottom: 15px;
		@include media($medium-screen-up){
			font-size:20px;
		}

		a{
			color:inherit;
		}
	}

	.categories{
		font-family: $base-font-family;
		color:$c-avacado;
		line-height: 1.25em;
		font-size:10px;
		margin-bottom: 10px;
		@include media($medium-screen-up){
			font-size: 15px;
		}
	}

	.type{
		text-align: right;
		line-height: 1.25em;

		.type-label{
			display: inline-block;
			font-family: $base-font-family;
			color:$c-maroon;
			font-size:12px;
			text-transform: uppercase;

			svg{
				font-size:18px;
			}
		}
	}


	.bottom-meta{
		
		border-top:1px solid $c-maroon;
		padding:7px 0;
		font-family: $base-font-family;
		color:$c-firefly;
		font-size:11px;
		text-transform: uppercase;
		@include media($medium-screen-up){
			padding:14px 0;
			font-size:13px;
		}

		&>span{
			display: inline-block;
			line-height: 1em;
			text-transform: uppercase;
		}
	}
}


/************************************
Book
*************************************/


.publication.shortcode-book{
	max-width:250px;
	text-align:center;
	margin-bottom:20px;
	@include media($medium-screen-up){
		text-align: left;
	}
	

	.publication-thumbnail{
		display: block;
		margin-bottom: 10px;
	}

	.publication-title{
		font-family: $heading-font-family;
		font-size:15px;
		color:$c-firefly;
		@include media($medium-screen-up){
			font-size:20px;
		}

		a{
			color:inherit;
		}

		.publication-subtitle{
			display: block;
			margin-top:5px;
			font-size:13px;
			@include media($medium-screen-up){
				font-size:16px;
			}
		}
	}
}


/************************************
Short Excerpt
*************************************/


.publication-short{
	margin-bottom:25px;

	.publication-title{
		font-family: $heading-font-family;
		color:$c-firefly;
		font-size:18px;
		margin-bottom: 0;
		@include media($medium-screen-up){
			font-size:24px;
		}

		a{
			color:inherit;
		}

		.publication-subtitle{
			display: block;
			font-size:15px;
			@include media($medium-screen-up){
				font-size:18px;
			}
		}
	}

	.date,
	.authors{
		font-family: $base-font-family;
		color:$c-avacado;
		font-style:italic;
		font-size:11px;
		@include media($medium-screen-up){
			font-size:14px;
		}
	}
}


/************************************
Spotlight
*************************************/

.spotlight{
	background:#fff;
	border:1px solid $c-firefly;
	padding:15px;

	h4{
		font-size:20px;
		color:$c-firefly;
		text-transform: uppercase;
		border:none;
	}

	a{
		display: block;
		position: relative;
		margin:0 !important;
 
		.spotlight-title{
			position: absolute;
			left:0;
			bottom:0;
			padding:12px;
			width:100%;
			font-family: $heading-font-family;
			color:#fff;
			font-size:20px;
			line-height: 1.2em;
			background:linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 1%,rgba(0,0,0,0.6) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

		}
	}
}


/************************************
People Bio
*************************************/

.people-post.bio{
	img{
		display: block;
		margin-bottom: 4px;
	}

	h3{
		font-family: $heading-font-family;
		font-size:20px;
		color:$c-firefly;
		border:none;
	}
}


/************************************
Featured Blocks
*************************************/

.featured-block{
	padding: 35px 0px 0 0;
    background: $c-firefly;
    color: #fff;
    margin-bottom: 35px;
}
