/*
Navigation
------------------------------------------------*/

.header-toggle{
	margin-left:35px;
}

#search-toggle{
	border:none;
	background:none;
	padding:10px;
	color:$c-avacado;
	transform:rotate(90deg);
	font-size:20px;
	span{
		@include elementInvisible;
	}
}

button#menu-toggle{
	border:none;
	display:block;
	height:40px;
	padding:0;
	text-decoration:none;
	z-index: 100;
	border-radius:0;
	background:transparent;
	overflow:hidden;
	@include media($medium-screen-up){
		display: none;
	}
	
	.icon{
		position: relative;
		height:25px;
		width:30px;
		line-height: 30px;
		color:#fff;
		margin:0 auto;
	
		&:after, & span:before, & span:after {
			border-radius: 3px;
			height: 3px; 
			width: 25px;
			background: $c-avacado;
			display: block;
			content: '';
			position: absolute;
			right:0px;
			cursor: pointer;		
		}
		//top bar
		& span:before {
			top: 4px;
		}
		//middle bar
		& span:after {
			top: 11px;
		}
		//bottom bar
		&:after{
			top:18px;
		} 
		 
		& span:before, & span:after {
			transition: all 250ms ease-in;
		}
	}

	.text{
		@include elementInvisible;
	} 

	&.menu-open{
		
		.icon{
			
			&:after{
				transform: translate(-999px, 0)
			}
			
			span:before {
				transform: translate(0, 7px) rotate(45deg);
			}
			span:after {
				transform: translate(0, 0px) rotate(-45deg);
			}
		}
	}

	&:focus{
		outline: none;

		.icon{
			
			color:#000;
			margin:0 auto;
		
			&:after, & span:before, & span:after {
				background:#000;
			}
		}
	}
}

#mainnav {
	font-family:$base-font-family;
}

//button on publications
#mega-menu-wrap-primary.mega-menu-wrap{
	#mega-menu-item-text-26{
		padding-bottom:0 !important;

		p{
			margin:0;
		}
	}

	.btn-clear{
		display: inline-block !important;
		color:$c-firefly !important;
		border-color:$c-firefly;

		&:hover{
			color:#fff !important;
			background:$c-firefly;
		}
	}

	//social medaia
	.social-links{

		li{
			margin-right:8px;
			display:inline-block;
		
			a{
				display:block;
				border-radius: 50%;
				background:$c-firefly;
				color:#fff !important;
				font-size:20px !important;
				width:33px;
				height:33px;
				line-height: 33px;
				text-align: center;
				
			}

			.text{
				@include elementInvisible;
			}
		}
	}
}

/***************************************
Mobile Menu
***************************************/
@include media($small-screen-down){
	#mega-menu-wrap-primary.mega-menu-wrap{
		

		#mega-menu-primary{
			& > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item{
				width:100%;
			}
		}
	}
}


@media all and (max-width:1100px){
	#mainnav{
		order:2;
	}



	#mega-menu-wrap-primary.mega-menu-wrap{
		position: static;

		.mega-menu-toggle .mega-toggle-block-1:after{
			color:$c-avacado;
		}

		.mega-menu-toggle .mega-toggle-block-1 .mega-toggle-label{
			@include elementInvisible;
		}

		#mega-menu-primary{
			position: absolute;
			left:0;
			top:100%;
			width:100%;
			border-bottom:1px solid #000;
			&:before{
				content:'';
				position: absolute;
				top:100%;
				left:0;
				width:100%;
				height:100vh;
				background:rgba(0,0,0,.3);
				display: block;
			}


		}
	}
}





/***************************************
Mega Menu
***************************************/

@media all and (min-width:1101px){
	#mega-menu-wrap-primary.mega-menu-wrap{
		position: static;
		

		#mega-menu-primary{
			position: static;
			display:flex;
			flex-wrap:wrap;
			justify-content:space-between;


			//First Level
			& > li.mega-menu-item{
				margin-left:45px;
				padding:18px 0;

				& > a.mega-menu-link{
					color:#031e2f !important;
					font-size:16px;
					display: block;
					line-height: 1em;
					height:auto;
					padding:5px;
					border-bottom:1px solid transparent;

					&:after{
						display: none;
					}
					&:hover{
						background:none;
						color:inherit;

					}

				}

				&:hover,
				&.mega-current-page-ancestor,
				&.mega-current-menu-item{
					& > a.mega-menu-link{
						color:$c-avacado !important;
						background:none;
						border-bottom:1px solid $c-firefly;
						&:before{
							font-family: "Font Awesome 5 Pro";
							content:'\f078';
							position: absolute;
							top:100%;
							left:50%;
							margin-left:-20px;
							width:40px;
							text-align: center;
							display: block;
							font-size:10px;
							color:$c-firefly !important;
						}
					}
				}

				& > ul.mega-sub-menu{
					top:85% !important; 
					padding:10px 0;
					& > li.mega-menu-item{
						//Submenus
						h4.mega-block-title{
							font-family: $heading-font-family;
							font-weight: 400;
							font-size:21px;
							text-transform: none;
							color:$c-firefly;
							border-bottom:1px solid $c-firefly;
							margin-bottom:12px;
						}
					}

					a{
						font-size:14px;
						color:#64645f;
						display: block;
						margin-bottom: 12px;
					}

					
				}
			}
		}
	}
}
	
