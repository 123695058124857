html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}



/*****************************
Theme Basics Settings
******************************/
.section-inner {
	@include sidePad;
	position: relative;
	max-width:$max-width;
	margin:0 auto;


}

.skip,
.elementInvisible{
	 @include elementInvisible();
}

/*****************************
Global Blocks
******************************/
@import 'blocks/header'; 
@import 'blocks/navigation';
@import 'blocks/banner';  
@import 'blocks/layout'; 
@import 'blocks/footer'; 
@import 'blocks/shortcodes'; 


/*****************************
Templates
******************************/
@import "templates/overview"; 
@import "templates/home"; 
@import "templates/search"; 
@import "templates/publications_overview"; 
@import "templates/publication_detail"; 
@import "templates/programs"; 
@import "templates/people"; 
@import "templates/events"; 

