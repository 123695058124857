// // Returns rem and px
// // Note: Assumes html font size 62.5%
// @mixin font-size($sizeValue: 1.6) {
//   font-size: ($sizeValue * 10) + px;
//   font-size: $sizeValue + rem;
// }

@mixin elementInvisible(){
  position: absolute !important;
  height: 1px; width: 1px; 
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

@mixin border(){
	border:1px solid #ccc;
} 

@mixin fillScreen(){
	margin-left:-$global-edge-padding;
	margin-right:-$global-edge-padding;
} 

@mixin sidePad(){
	padding-left:$global-edge-padding;
	padding-right:$global-edge-padding;
} 

@mixin redTitle(){
	font-family: $base-font-family;
	font-weight: 600;
	color:$c-maroon;
	border-bottom:1px solid $c-maroon;
	text-transform: uppercase;
	font-size:15px;
	padding-bottom:5px;
	height: 45px;
	line-height: 45px;
	margin-bottom: 25px;
	@include media($large-screen-up){
		text-align:center;
		font-size:18px;
	}
}


@mixin topicTitle(){
	font-family: $base-font-family;
	color:$c-maroon;
	font-size:11px;
	font-weight: 600;
	padding:12px 12px 12px 35px;
	text-transform: uppercase;
	background:url(../img/icon-diamond.png) 0 0 no-repeat;
	border-bottom:1px solid $c-maroon;
	margin-bottom: 10px;
	@include media($medium-screen-up){
		font-size:15px;
	}
}
