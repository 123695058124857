/*
Header
------------------------------------------------*/

#header {
    position: relative;
	box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.3); 
	z-index: 10;
}

.h-w1{
	background:$c-firefly;
	color:#fff;
}

.h-w2{
	background:#fff;
}

.header-util{
	padding:10px 0;
	font-family:$base-font-family;
	transition:all .25s;

	#util{
		display: flex;
		justify-content: flex-end;
		align-items:center;
	}
	

	ul{
		display: flex;
		justify-content: flex-end;
		align-items:center;

		li{
			border-left:1px solid #fff;
			&:first-child{
				border-left:none;
			}

			a{
				color:#fff;
				text-decoration: none;
				font-size:15px;
				display: block;
				padding:0 13px;
				line-height: 1em;
			}
		}
	}

	.cart-link{
		font-size:20px;
		color:#225e84;
		margin-left:15px;

		span{
			@include elementInvisible;
		}

	}
}


.h-w2{
	background:#fff;
	.section-inner{
		// @include media($small-screen-down){
		// 	display: flex;
		// 	justify-content: space-between;
		// 	align-items:center;
		// }

		//@include media($large-screen-up){
			display: flex;
			justify-content: space-between;
			align-items:center;
		//}
		
	}
}

.nav-wrap{
	display: flex;
	flex-wrap:wrap;
	justify-content: space-between;
	align-items:center;

}

#logo {
	transition:all .25s;
	flex: 1 0 50%;
	max-width:100%;
	@include media($small-screen-up){
		flex: 1 0 250px;
	}

	.sticky-logo{
		display: none;
	}
}

#search-toggle{

}

.search-wrap{
	position: absolute;
    top: 100%;
    left:0;
    background: rgba(0,0,0,.5);
    width: 100%;
    padding: 20px;
    display: none;

    @at-root .search-open &{
    	display: block;
    }

    form{
    	position: relative;
    }

    button{
    	position: absolute;
	    right: 5px;
	    top: 5px;
	    background: #001e32;
	    color: #fff;
	    text-transform: uppercase;
	    font-size: 18px;
    	padding: 7px 10px;
	    border: 1px solid transparent;
    }
}

#header.sticky{
	@include media($large-screen-up){
		position: sticky;
		top:0;
		left:0;
		transition:all .25s;

		.header-util{
			padding:0px 0;
		}


		#logo{
			transition:all .25s;
			padding:10px;
		
			img{
				display: none;
			}

			.sticky-logo{
				display: block;
			}
		}
	}
}

.no-csspositionsticky .sticky{
	width:100%;
}
