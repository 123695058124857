// Typography
$base-font-family:'Open Sans', Arial, sans-serif;

$heading-font-family: 'Crimson Text', Times New Roman, serif;

// Font Sizes
$base-font-size: 18px;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
// First Four make up color palette. Add more as required 
// Handy naming tool : http://chir.ag/projects/name-that-color 
$c-firefly:#001e32; /*#0a1e2d;*/
$c-maroon:#780000;
$c-grey:#64645f;
$c-whiterock:#e6e6c8;
$c-avacado:#919164;

$prim-color: $c-firefly;
$sec-color: $c-maroon;
$prim-color-2: #999;
$sec-color-2: #ddd;
  
// Font Colors
$base-font-color: $c-grey;
$action-color: $c-maroon;

//Typography Colors
$first-heading-color: $sec-color;
$second-heading-color: $prim-color;

// Border
$base-border-color: $sec-color-2;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);
$tertiary-background-color:violet;

// Other Background Colors
$first-alternative-background-color:limegreen;
$second-alternative-background-color:crimson;

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

//Tab Colors
$tabActive:$prim-color;
$tabBorder:$prim-color;

//Accordion Colors
$accordion-color:$prim-color;


// Animations
$base-duration: 150ms;
$base-timing: ease;


//Add colors to styleguide
/*doc
---
title: Background Colors
name: background
category: Base CSS
---

We have a few background colors that can be used in various contexts.
These are not for use as the entire page background but instead for
specific components and modules on the page.

<div class="line txtC">
    <div class="col cols6 ">
      <div class="docSwatch primary-color"></div>
      <code>primary-color</code>
    </div>
    <div class="col cols6 ">
      <div class="docSwatch secondary-color"></div>
      <code>secondary-color</code>
    </div>
    <div class="col cols6 ">
      <div class="docSwatch accent-color"></div>
      <code>accent-color</code>
    </div>
    <div class="col cols6 lastCol">
      <div class="docSwatch  sec-accent-color"></div>
      <code>sec-accent-color</code>
    </div>
</div>
*/
.primary-color{background:$prim-color;}

.secondary-color{background:$sec-color;}

.accent-color{background:$prim-color-2;} 

.sec-accent-color{background:$sec-color-2;} 
